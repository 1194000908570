import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData
} from "@remix-run/react";
import { LiveReload } from "./components/LiveReload";
import { json } from "@remix-run/node";

export async function loader() {
  return json({
    ENV: {
      SHOPIFY_API_KEY: process.env.SHOPIFY_API_KEY,
      BASE_URL: process.env.SHOPIFY_BASE_URL,
    },
  });
}


export default function App() {

  // The data returned from loader() is available in the `data` prop:
  const data = useLoaderData();

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>


        <Outlet />
        <ScrollRestoration />
        <LiveReload port={3000} />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(
              data.ENV
            )}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}
